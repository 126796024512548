<template lang="pug">
  .matchings(:class="{ loading }")
    BAlert.create-account-alert(
      show
      variant="warning"
    ) {{ $t("shop_matching.change_source_warning") }}
    TopBar(
      :allow-save="allowSave"
      page-type="shops"
      @add-new="handleAddNew"
      @save="saveChanges"
      @search="applySearch"
    )
    .table-wrapper
      AppOverlayLoader(:state="loading")
      ShopsTable(
        :shops="items"
        :shops-added="itemsAdded"
        :sources-shops-by-ota-id="sourcesShopsByOtaId"
        :inventory-groups-list="inventoryGroupsList"
        :inventory-groups-loading="inventoryGroupsLoading"
        :create-inventory-group="createInventoryGroup"
        :update-inventory-group="updateInventoryGroup"
        :delete-inventory-group="deleteInventoryGroup"
        :update-shop="updateItemByIndex"
        :update-added-shop="updateAddedItemByIndex"
        :delete-shop="deleteItemByIndex"
        :delete-added-shop="deleteAddedItemByIndex"
        :selected-sources-shops="selectedSourcesShops"
        :add-selected-sources-shop="addSelectedSourcesShop"
        :remove-selected-sources-shop="removeSelectedSourcesShop"
        :invalid-item-ids="invalidItemIds"
        :is-active-shops-count-limit-exceed="isActiveItemsCountLimitExceed"
        :sorting-data="sorting"
        :sources-shops-loading="sourcesShopsLoading"
        @sorting="handleChangeSorting"
      )
      AppPagination(
        :total="pagination.total_count"
        :per-page="pagination.per_page"
        :current-page="pagination.current_page"
        @change-pagination-data="handleChangePagination"
      )
        template(
          v-if="activeShopsInfo"
          v-slot:prepend
        )
          .active-shops-info-wrapper
            .active-shops-info(
              :class="{ 'error': isActiveItemsCountLimitExceed }"
            ) {{ activeShopsInfo }}
            AppTooltip(
              :title="$t('shop_matching.active_shops_tooltip')"
              placement="auto"
            )
</template>

<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import sourcesShopsModule from "@/config/store/sources/shops"
  import inventoryGroupsModule from "@/config/store/inventory_groups"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withMatchingPageMethods from "@/mixins/matching_page/withIndexMethods"
  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  // components
  import AppTooltip from "@/components/elements/AppTooltip"

  // misc
  import { mapGetters } from "vuex"
  import { isSharedInventoryEnabled as isOrganizationWithSharedInventory } from "@/helpers/organization"

  const shopMatchingsMixin = withStoreModule(shopMatchingModule, {
    resetState: true,
    name: "shopMatching",
    readers: {
      items: "items",
      itemsAdded: "itemsAdded",
      itemsDeleted: "itemsDeleted",
      activeItemsCount: "activeItemsCount",
      shopsLoading: "loading",
      pagination: "pagination",
      invalidItemIds: "invalidItemIds",
      sorting: "sorting"
    },
    actions: {
      fetchItemsAction: "FETCH_ITEMS",
      addItem: "ADD_ITEM",
      updateItems: "UPDATE_ITEMS",
      updateItemByIndex: "UPDATE_ITEM_BY_INDEX",
      updateAddedItemByIndex: "UPDATE_ADDED_ITEM_BY_INDEX",
      deleteItemByIndex: "DELETE_ITEM_BY_INDEX",
      deleteAddedItemByIndex: "DELETE_ADDED_ITEM_BY_INDEX",
      applySearch: "APPLY_SEARCH"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setInvalidItemsIds: "SET_INVALID_ITEM_IDS",
      resetAddedItems: "RESET_ITEMS_ADDED",
      resetDeletedItems: "RESET_ITEMS_DELETED",
      resetItems: "RESET_ITEMS",
      setSorting: "SET_SORTING"
    },
    getters: ["itemsUpdated"]
  })

  const sourcesShopsMixin = withStoreModule(sourcesShopsModule, {
    resetState: true,
    name: "sourcesShops",
    readers: {
      sourcesShopsLoading: "loading",
      selectedSourcesShops: "selectedItems",
      selectedSourcesShopsLoading: "selectedItemsLoading"
    },
    getters: { sourcesShopsByOtaId: "groupedByOta" },
    actions: {
      fetchSourcesShops: "FETCH_ITEMS",
      fetchSelectedSourcesShops: "FETCH_SELECTED_ITEMS"
    },
    mutations: {
      addSelectedSourcesShop: "ADD_SELECTED_ITEM",
      removeSelectedSourcesShop: "REMOVE_SELECTED_ITEM"
    }
  })

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "inventoryGroups",
    readers: { inventoryGroupsList: "items", inventoryGroupsLoading: "loading" },
    actions: {
      fetchInventoryGroups: "FETCH_ITEMS",
      createInventoryGroup: "CREATE_ITEM",
      updateInventoryGroup: "UPDATE_ITEM",
      deleteInventoryGroup: "DELETE_ITEM"
    },
    mutations: {
      setGroupsSorting: "SET_SORTING"
    }
  })

  export default {
    computed: {
      ...mapGetters(["currentOrganization"]),
      isOrganizationWithSharedInventory,

      loading({ shopsLoading, inventoryGroupsLoading, sourcesShopsLoading, selectedSourcesShopsLoading }) {
        return shopsLoading || inventoryGroupsLoading || sourcesShopsLoading || selectedSourcesShopsLoading
      },

      activeShopsInfo() {
        const {
          loading,
          activeItemsCount,
          currentOrganization: { max_active_shops_count }
        } = this

        if (max_active_shops_count === 0 || loading) {
          return ""
        }

        return this.$t("shop_matching.active_max_shops_count_info", {
          activeCount: activeItemsCount,
          maxCount: max_active_shops_count
        })
      },

      isActiveItemsCountLimitExceed() {
        const {
          activeItemsCount,
          currentOrganization: { max_active_shops_count }
        } = this

        return max_active_shops_count !== 0 && activeItemsCount >= max_active_shops_count
      }
    },

    components: {
      TopBar: () => import("@/components/layout/TopBar"),
      ShopsTable: () => import("@/pages/ShopMatching/ShopsTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppTooltip
    },

    mixins: [
      shopMatchingsMixin,
      sourcesShopsMixin,
      inventoryGroupsMixin,
      withMatchingPageMethods,
      withToyotaOrganizationsMountedOrder
    ],

    mounted() {
      Promise.all([
        this.fetchInventoryGroupsForSharedInventory(),
        this.fetchItems(),
        this.fetchSelectedSourcesShops(),
        this.fetchSourcesShops()
      ])
    },

    data() {
      return {
        activeCountShopsError: ""
      }
    },

    methods: {
      setInventoryGroupsSorting(order) {
        this.setGroupsSorting(order)
      },

      saveChanges() {
        this.updateItems().then(() => {
          this.fetchInventoryGroupsForSharedInventory()
        })
      },

      fetchInventoryGroupsForSharedInventory() {
        if (this.isOrganizationWithSharedInventory) {
          return this.fetchInventoryGroups()
        } else {
          return Promise.resolve()
        }
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
</style>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .matchings
    .table-wrapper
      position: relative

    .active-shops-info-wrapper
      align-items: center
      display: flex
      justify-content: center
      .active-shops-info
        border-radius: 0.25rem
        border: 1px solid $default-purple
        color: $default-black
        font-size: 0.8rem
        padding: 7px 10px
        transition: all 0.3s ease-in

        &.error
          border: 1px solid $default-red
          color: $default-red
</style>
